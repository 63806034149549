<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="[
                  'last3Days',
                  'last7Days',
                  'last14Days',
                  'last30Days',
                ]"
                :max-selectable-days="30"
                :timezone="asup.timezone"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="onlyErrors"
                switch
              >
                Errors only
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="search"
                size="sm"
                placeholder="Search for workflow..."
              />
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-overlay :show="isLoading">
      <b-card title="Workflow Job Duration">
        <b-form>
          <b-form-group label="Show/Hide label">
            <div class="custom-control custom-control-inline custom-checkbox">
              <b-form-checkbox
                v-model="wfJobGantt.labels.showPolicy"
                class="mr-1"
              >
                Policy
              </b-form-checkbox>
              <b-form-checkbox
                v-model="wfJobGantt.labels.showWorkflow"
                class="mr-1"
              >
                Workflow
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-form>
        <apexchart
          v-if="mounted"
          type="rangeBar"
          :height="wfJobChartHeight"
          :options="workflowDurationChartOptions"
          :series="[workflowDurationChartSeries]"
          @click="chartWfJobClickHandler"
        />
      </b-card>
      <b-card title="Clone Job Duration">
        <b-form>
          <b-form-group label="Show/Hide label">
            <div class="custom-control custom-control-inline custom-checkbox">
              <b-form-checkbox
                v-model="cloneJobGantt.labels.showPolicy"
                class="mr-1"
              >
                Policy
              </b-form-checkbox>
              <b-form-checkbox
                v-model="cloneJobGantt.labels.showWorkflow"
                class="mr-1"
              >
                Workflow
              </b-form-checkbox>
              <b-form-checkbox
                v-model="cloneJobGantt.labels.showAction"
                class="mr-1"
              >
                Action
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-form>
        <apexchart
          v-if="mounted"
          type="rangeBar"
          :height="cloneJobChartHeight"
          :options="cloneDurationChartOptions"
          :series="[cloneDurationChartSeries]"
          @click="chartCloneJobClickHandler"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import {
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import NetWorkerService from '@/service/networker.service'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    DateRangePicker,
    BFormCheckbox,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    apexchart: VueApexCharts,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last7Days',
      },
      mounted: false,
      workflowDurationData: [],
      onlyErrors: false,
      isLoading: false,
      search: null,
      cloneJobGantt: {
        labels: {
          showPolicy: false,
          showWorkflow: true,
          showAction: false,
        },
      },
      wfJobGantt: {
        labels: {
          showPolicy: false,
          showWorkflow: true,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    workflowDurationChartOptions() {
      const self = this
      return {
        chart: {
          id: 'workflowDurationChart',
          type: 'rangeBar',
          animations: {
            enabled: false,
          },
        },
        noData: {
          text: 'No workflow jobs found',
          style: {
            fontSize: '18px',
          },
        },
        markers: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        yaxis: {
          labels: {
            maxWidth: 500,
            formatter: val => {
              if (typeof val === 'string') {
                // eslint-disable-next-line no-unused-vars
                const [policy, workflow] = val.split('###') // unique name is seperated by ###

                let label = ''
                if (this.wfJobGantt.labels.showPolicy) {
                  label += `${policy} / `
                }
                if (this.wfJobGantt.labels.showWorkflow) {
                  label += `${workflow}`
                }

                if (label.endsWith(' / ')) {
                  label = label.slice(0, label.length - 3)
                }

                return label
              }

              return ''
            },
          },
        },
        xaxis: {
          type: 'datetime',
          min: moment(this.timeRange.startDate).valueOf(),
          max: moment(this.timeRange.endDate).valueOf(),
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          ({ dataPointIndex }) => {
            const item = self.workflowDurationChartSeries.data[dataPointIndex]
            if (item) {
              switch (item.properties.status) {
                case 30:
                  return $themeColors.danger
                case 20:
                  return $themeColors.warning
                case 10:
                  return $themeColors.info
                case 0:
                  return $themeColors.success
                default:
                  return $themeColors.secondary
              }
            }

            return $themeColors.secondary
          },
        ],
        tooltip: {
          custom: opts => {
            // eslint-disable-next-line no-unused-vars
            const seriesItem = self.workflowDurationChartSeries.data[opts.dataPointIndex]
            const start = moment(opts.y1)
            const end = moment(opts.y2)

            const duration = moment.duration(end.diff(start))

            return (
              `${'<table class="table table-sm table-borderless p-1 shadow-lg">'
                + '<tr><th>Group</th><td>'}${
                seriesItem.properties.group
              }</td></tr>`
              + `<tr><th>Policy</th><td> ${seriesItem.properties.policy}</td></tr>`
              + `<tr><th>Workflow</th><td> ${seriesItem.properties.workflow}</td></tr>`
              + `<tr><th>Start</th><td> ${self.$options.filters.formatDateTime(
                start,
              )}</td></tr>` // do not adjust TZ because series data is already adjusted with TZ
              + `<tr><th>End</th><td> ${self.$options.filters.formatDateTime(
                end,
              )}</td></tr>`
              + `<tr><th>Duration</th><td> ${duration.humanize()} (${duration.days()}d ${moment
                .utc(end.diff(start))
                .format('HH[h] mm[m] ss[s]')})</td></tr>`
              + `<tr><th>Status</th><td> ${self.$options.filters.jobStatusDisplayName(
                seriesItem.properties.status,
              )}</td></tr>`
              + '</table>'
            )
          },
        },
      }
    },
    cloneDurationChartOptions() {
      const self = this
      return {
        chart: {
          id: 'cloneDurationChart',
          type: 'rangeBar',
          animations: {
            enabled: false,
          },
        },
        noData: {
          text: 'No clone jobs found',
          style: {
            fontSize: '18px',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        yaxis: {
          labels: {
            maxWidth: 500,
            formatter: val => {
              if (typeof val === 'string') {
                // eslint-disable-next-line no-unused-vars
                const [policy, workflow, action] = val.split('###') // unique name is seperated by ###

                let label = ''
                if (this.cloneJobGantt.labels.showPolicy) {
                  label += `${policy} / `
                }
                if (this.cloneJobGantt.labels.showWorkflow) {
                  label += `${workflow} / `
                }
                if (this.cloneJobGantt.labels.showAction) {
                  label += `${action}`
                }

                if (label.endsWith(' / ')) {
                  label = label.slice(0, label.length - 3)
                }

                return label
              }

              return ''
            },
          },
        },
        xaxis: {
          type: 'datetime',
          min: moment(this.timeRange.startDate).valueOf(),
          max: moment(this.timeRange.endDate).valueOf(),
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          ({ dataPointIndex }) => {
            const item = self.cloneDurationChartSeries.data[dataPointIndex]
            if (item) {
              switch (item.properties.status) {
                case 30:
                  return $themeColors.danger
                case 20:
                  return $themeColors.warning
                case 10:
                  return $themeColors.info
                case 0:
                  return $themeColors.success
                default:
                  return $themeColors.secondary
              }
            }

            return $themeColors.secondary
          },
        ],
        tooltip: {
          custom: opts => {
            // eslint-disable-next-line no-unused-vars
            const seriesItem = self.cloneDurationChartSeries.data[opts.dataPointIndex]
            const start = moment(opts.y1)
            const end = moment(opts.y2)

            const duration = moment.duration(end.diff(start))

            return (
              `${'<table class="table table-sm table-borderless p-1 shadow-lg">'
                + '<tr><th>Group</th><td>'}${
                seriesItem.properties.group
              }</td></tr>`
              + `<tr><th>Policy</th><td> ${seriesItem.properties.policy}</td></tr>`
              + `<tr><th>Workflow</th><td> ${seriesItem.properties.workflow}</td></tr>`
              + `<tr><th>Action</th><td> ${seriesItem.properties.action}</td></tr>`
              + `<tr><th>Clone Job Name</th><td> ${seriesItem.properties.cloneJobName}</td></tr>`
              + `<tr><th>Start</th><td> ${self.$options.filters.formatDateTime(
                start,
              )}</td></tr>` // do not convert to TZ, because series data is already adjusted to TZ
              + `<tr><th>End</th><td> ${self.$options.filters.formatDateTime(
                end,
              )}</td></tr>`
              + `<tr><th>Duration</th><td> ${duration.humanize()} (${duration.days()}d ${moment
                .utc(end.diff(start))
                .format('HH[h] mm[m] ss[s]')})</td></tr>`
              + `<tr><th>Status</th><td> ${self.$options.filters.jobStatusDisplayName(
                seriesItem.properties.status,
              )}</td></tr>`
              + '</table>'
            )
          },
        },
      }
    },
    wfJobChartHeight() {
      const wfDurationData = this.filteredWorkflowDurationData.filter(
        x => x.jobDurations.length > 0,
      )
      return this.calculateChartHeight(wfDurationData.length)
    },
    cloneJobChartHeight() {
      const cloneDurationData = this.filteredWorkflowDurationData.filter(
        x => x.cloneJobDurations.length > 0,
      )
      return this.calculateChartHeight(cloneDurationData.length)
    },
    filteredWorkflowDurationData() {
      if (this.search) {
        return this.workflowDurationData.filter(x => x.workflow.toLowerCase().includes(this.search.toLowerCase()))
      }

      return this.workflowDurationData
    },
    workflowDurationChartSeries() {
      let series = []

      for (let i = 0; i < this.filteredWorkflowDurationData.length; i += 1) {
        const wfHasErrors = this.filteredWorkflowDurationData[
          i
        ].jobDurations.some(x => x.status !== 0)
        if (!this.onlyErrors || (this.onlyErrors && wfHasErrors)) {
          for (
            let j = 0;
            j < this.filteredWorkflowDurationData[i].jobDurations.length;
            j += 1
          ) {
            const seriesItem = {
              x: `${this.filteredWorkflowDurationData[i].policy}###${this.filteredWorkflowDurationData[i].workflow}`,
              y: [
                this.adjustByTimezone(
                  moment.utc(
                    this.filteredWorkflowDurationData[i].jobDurations[j].start,
                  ),
                ).valueOf(),
                this.adjustByTimezone(
                  moment.utc(
                    this.filteredWorkflowDurationData[i].jobDurations[j].end,
                  ),
                ).valueOf(),
              ],
              properties: {
                group: this.filteredWorkflowDurationData[i].group,
                policy: this.filteredWorkflowDurationData[i].policy,
                workflow: this.filteredWorkflowDurationData[i].workflow,
                workflowJobId: this.filteredWorkflowDurationData[i]
                  .jobDurations[j].id,
                status: this.filteredWorkflowDurationData[i].jobDurations[j]
                  .status,
              },
            }
            series.push(seriesItem)
          }
        }
      }

      // sort depending on shown labels
      if (this.wfJobGantt.labels.showPolicy) {
        series = series.sort((a, b) => (a.properties.policy > b.properties.policy ? 1 : -1))
      } else if (this.wfJobGantt.labels.showWorkflow) {
        series = series.sort((a, b) => (a.properties.workflow > b.properties.workflow ? 1 : -1))
      }

      return {
        name: 'Duration',
        data: series,
      }
    },
    durationsByWorkflow() {
      // group by is for debugging with VUE developer tools...
      return this.filteredWorkflowDurationData.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x.workflow] = rv[x.workflow] || []).push(x)
        return rv
      }, {})
    },
    cloneDurationChartSeries() {
      let series = []

      for (let i = 0; i < this.filteredWorkflowDurationData.length; i += 1) {
        const wfHasErrors = this.filteredWorkflowDurationData[
          i
        ].cloneJobDurations.some(x => x.status !== 0)
        if (!this.onlyErrors || (this.onlyErrors && wfHasErrors)) {
          // group by action
          const groupedByAction = this.filteredWorkflowDurationData[
            i
          ].cloneJobDurations.reduce((p, c) => {
            // eslint-disable-next-line no-param-reassign
            p[c.action] = p[c.action] || []
            p[c.action].push(c)
            return p
          }, {})

          // eslint-disable-next-line no-restricted-syntax
          for (const [action, cloneJobDurations] of Object.entries(
            groupedByAction,
          )) {
            for (let j = 0; j < cloneJobDurations.length; j += 1) {
              const seriesItem = {
                // create unique name so that apexchart does not group items automatically with same name
                x: `${this.filteredWorkflowDurationData[i].policy}###${this.filteredWorkflowDurationData[i].workflow}###${action}`,
                y: [
                  this.adjustByTimezone(
                    moment.utc(cloneJobDurations[j].start),
                  ).valueOf(),
                  this.adjustByTimezone(
                    moment.utc(cloneJobDurations[j].end),
                  ).valueOf(),
                ],
                properties: {
                  group: this.filteredWorkflowDurationData[i].group,
                  policy: this.filteredWorkflowDurationData[i].policy,
                  workflow: this.filteredWorkflowDurationData[i].workflow,
                  workflowJobId: cloneJobDurations[j].workflowJobId,
                  cloneJobName: cloneJobDurations[j].cloneJobName,
                  action: cloneJobDurations[j].action,
                  status: cloneJobDurations[j].status,
                },
              }
              series.push(seriesItem)
            }
          }
        }
      }

      // sort depending on shown labels
      if (this.cloneJobGantt.labels.showPolicy) {
        series = series.sort((a, b) => (a.properties.policy > b.properties.policy ? 1 : -1))
      } else if (this.cloneJobGantt.labels.showWorkflow) {
        series = series.sort((a, b) => (a.properties.workflow > b.properties.workflow ? 1 : -1))
      } else if (this.cloneJobGantt.labels.showAction) {
        series = series.sort((a, b) => (a.properties.action > b.properties.action ? 1 : -1))
      }

      return {
        name: 'Duration',
        data: series,
      }
    },
  },
  watch: {
    timeRange() {
      this.loadData()
    },
    onlyErrors() {
      this.loadData()
    },
  },
  beforeMount() {
    if (this.isCurrentUserHost === true) {
      this.onlyErrors = true
    }
  },
  mounted() {
    this.loadData()
    this.mounted = true
  },
  methods: {
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz
        .zone(this.asup.timezone)
        .utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
    loadData() {
      this.isLoading = true

      const params = {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        onlyErrors: this.onlyErrors,
      }

      NetWorkerService.getWorkflowJobDurationListAsync(this.asup.id, params, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.workflowDurationData = result.items
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // eslint-disable-next-line no-unused-vars
    chartWfJobClickHandler(event, chartContext, config) {
      if (config.dataPointIndex !== -1) {
        const { workflowJobId } = this.workflowDurationChartSeries.data[
          config.dataPointIndex
        ].properties
        this.$router.push({
          name: 'networker-detail.workflow-job-detail',
          params: { wfJobId: workflowJobId },
        })
      }
    },
    chartCloneJobClickHandler(event, chartContext, config) {
      if (config.dataPointIndex !== -1) {
        const { workflowJobId } = this.cloneDurationChartSeries.data[
          config.dataPointIndex
        ].properties
        this.$router.push({
          name: 'networker-detail.workflow-job-detail',
          params: { wfJobId: workflowJobId },
        })
      }
    },
    calculateChartHeight(dataCount) {
      const heightPerItem = 45
      const chartHeightNoData = 150

      if (dataCount === 0) {
        return `${chartHeightNoData}px`
      }

      if (dataCount === 1) {
        // there is a bug that if chart contains less or equal 2 lines, the lines will be really small.
        return `${dataCount * heightPerItem * 2.2}px`
      }
      if (dataCount <= 2) {
        // there is a bug that if chart contains less or equal 2 lines, the lines will be really small.
        return `${dataCount * heightPerItem * 1.5}px`
      }

      return `${dataCount * heightPerItem}px`
    },
  },
}
</script>

<style scoped>
.form-inline {
  place-items: flex-start;
}

.form-group {
  margin-right: 15px;
}
</style>
